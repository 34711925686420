<template>
<div class="main">
	<h1>Not Found</h1>
	<router-link to="/">Return home...</router-link>
</div>
</template>

<script>
export default {
	name: 'not_found'
    // data() {
    //     return {
    //         homeURL: '/',
	// 	}
	// },
	// created() {
	// 	if (document.location.hostname.indexOf('.test') > -1) {
	// 		this.homeURL = '/frontend/'
	// 	}
	// }
}
</script>

<style lang="scss" scoped>

</style>
